<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!subscription">
      <span class="loader"></span>
    </span>
    <div v-if="subscription" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        :value="currentTab"
        @onTabChanged="tabChanged"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="fa-regular fa-list"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <subscription-view-global :subscription="subscription" />
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-history"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <subscription-view-logs :subscription="subscription" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import SubscriptionViewGlobal from "../partials/SubscriptionViewGlobal.vue";
import SubscriptionViewLogs from "../partials/SubscriptionViewLogs.vue";
import hasTabs from "@/mixins/has-tabs";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    SubscriptionViewGlobal,
    SubscriptionViewLogs,
  },

  mixins: [requestErrorMixin, hasTabs],

  props: {
    subscriptionId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      subscription: null,
      loading: false,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("subscriptions/get", this.subscriptionId);
        this.subscription = this.$store.getters["subscriptions/subscription"];
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async renewSubscription() {
      this.loading = true;
      try {
        const invoice = await this.$store.dispatch(
          "subscriptions/renew",
          this.subscription.id
        );

        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("SUBSCRIPTIONS.SUBSCRIPTION_INVOICE_ADDED"),
        });
        this.loading = false;

        this.$router.push(this.$objectViewRoute(invoice));
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
